import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/AboutPage/AboutUs";
import Gallery from "./pages/Gallery";
import Blog from "./pages/Blog";
import Contacts from "./pages/Contacts";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import Login from "./pages/Login";
import JoinUs from "./pages/JoinUs";
import UpcomingEvents from "./pages/UpcomingEvents";
import ScrollToTop from "./ScrollToTop";
import EventDetailsPage from "./pages/EventDetailsPage";

// Admin Pages
import ManageBlogs from "./pages/Admin/ManageBlogs";
import ManageEvents from "./pages/Admin/ManageEvents";
import ManageGallery from "./pages/Admin/Managegallery";
import ManageRequests from "./pages/Admin/ManageRequests";
import ManageTeam from "./pages/Admin/ManageTeam";

import Footer from "./components/Footer";
import "./index.css";
import AdminSidebar from "./pages/Admin/AdminSidebar";
import { useAuth } from "./context/AuthContext";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./Firebase";

function App() {
  const location = useLocation();
  const { currentUser, setCurrentUser } = useAuth();
  const [loading, setLoading] = useState(true);

  // Determine if the current route is an admin route.
  const isAdminRoute = location.pathname.startsWith("/admin");
  const isAdminDashboardRoute = location.pathname.startsWith(
    "/admin/admindashboard-content/"
  );

  useEffect(() => {
    // Listen for authentication state changes.
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [setCurrentUser]);

  if (loading) {
    // Render a loading spinner until the auth state has been determined.
    return (
      <div className="fixed inset-0 flex justify-center items-center bg-[#e5ecda] z-50">
        <svg
          id="icon"
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="100.000000pt"
          height="100.000000pt"
          viewBox="0 0 1920.000000 1920.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,1920.000000) scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="none"
          >
            <path
              d="M4566 13581 c-3 -5 -18 -11 -33 -14 -70 -13 -126 -44 -189 -107 -70
          -68 -99 -118 -128 -212 -17 -57 -19 -58 -54 -58 -63 -1 -191 -38 -273 -80 -21
          -11 -44 -20 -52 -20 -8 0 -61 -23 -118 -51 -89 -44 -119 -66 -211 -153 -59
          -55 -144 -135 -190 -178 l-83 -76 -3 -201 -3 -200 -51 -103 c-57 -118 -92
          -168 -115 -168 -9 0 -69 36 -135 80 -65 44 -125 80 -132 80 -21 0 -219 219
          -302 334 -20 26 -45 72 -56 102 -23 60 -35 67 -44 27 -3 -16 -10 -41 -15 -57
          -12 -39 -12 -189 1 -202 9 -9 17 -65 37 -243 7 -70 131 -322 199 -406 85 -105
          376 -390 414 -405 19 -7 65 -33 102 -57 37 -24 71 -43 76 -43 5 0 24 -24 42
          -52 42 -67 133 -162 185 -193 22 -13 84 -68 137 -122 75 -77 113 -106 171
          -135 l75 -38 389 0 c304 0 403 -4 458 -15 43 -9 124 -15 209 -15 77 0 153 -4
          170 -9 17 -5 51 -15 76 -22 25 -7 70 -23 100 -37 50 -22 64 -24 165 -19 60 3
          122 8 137 12 23 6 29 3 45 -26 15 -29 19 -68 24 -269 3 -129 10 -250 14 -269
          4 -18 36 -89 71 -157 35 -67 64 -125 64 -128 0 -3 -83 -6 -185 -6 -131 0 -197
          4 -227 14 -24 8 -101 17 -173 20 -144 6 -203 12 -255 26 -19 5 -60 11 -90 14
          -111 10 -222 27 -240 36 -10 6 -37 10 -59 10 -22 0 -50 4 -63 9 -13 4 -48 15
          -78 24 -30 9 -85 25 -123 36 -37 12 -77 21 -90 21 -12 0 -33 6 -47 13 -14 7
          -52 19 -85 26 -33 7 -73 18 -90 26 -16 7 -55 18 -85 24 -50 11 -176 51 -225
          71 -11 5 -45 16 -75 25 -30 9 -64 20 -75 25 -11 4 -52 18 -91 29 -47 14 -112
          47 -186 94 -144 91 -135 85 -204 132 -32 22 -66 45 -75 50 -79 48 -233 156
          -271 191 -26 24 -51 44 -55 44 -4 0 -29 19 -54 43 -49 45 -133 108 -161 119
          -18 7 -55 62 -142 208 -32 52 -61 99 -67 105 -12 12 -12 -366 0 -397 5 -13 15
          -50 21 -83 7 -33 18 -80 25 -105 8 -25 19 -64 25 -87 6 -24 39 -84 73 -134 34
          -50 72 -105 83 -123 12 -17 31 -40 43 -50 11 -11 21 -23 21 -29 0 -29 139
          -172 235 -241 22 -16 54 -42 70 -57 33 -31 181 -109 206 -109 8 0 19 -4 25 -9
          15 -14 214 -102 284 -126 162 -55 379 -127 425 -140 90 -27 199 -56 255 -70
          63 -15 166 -46 260 -80 70 -25 110 -35 235 -60 44 -9 99 -20 123 -25 23 -6 58
          -10 78 -10 19 0 44 -5 55 -10 20 -11 118 -28 209 -36 30 -3 69 -11 85 -19 17
          -7 66 -16 110 -19 44 -4 100 -13 125 -21 73 -22 161 -35 237 -35 40 0 84 -5
          100 -12 15 -6 75 -15 133 -20 171 -13 254 -23 268 -31 6 -5 12 -28 12 -52 0
          -34 -14 -72 -60 -164 -51 -101 -60 -128 -60 -173 0 -57 19 -129 52 -193 12
          -22 36 -69 54 -104 18 -36 58 -90 89 -121 47 -48 54 -59 42 -69 -18 -14 -732
          -8 -892 8 -55 5 -149 14 -210 20 -60 5 -136 15 -168 20 -32 6 -64 8 -71 5 -7
          -3 -33 0 -57 6 -24 6 -62 15 -84 20 -22 5 -80 20 -130 34 -184 52 -200 56
          -220 56 -11 0 -31 6 -45 13 -14 7 -59 19 -100 27 -41 7 -84 18 -94 23 -11 5
          -52 17 -91 27 -39 9 -80 22 -90 28 -11 5 -49 17 -85 25 -85 20 -210 56 -232
          68 -10 5 -26 9 -37 9 -20 0 -66 17 -176 65 -16 8 -66 27 -110 44 -191 73 -251
          101 -310 146 -22 17 -53 40 -69 50 -16 11 -39 27 -51 35 -33 23 -76 50 -120
          79 -22 14 -81 64 -131 111 -50 47 -136 126 -190 176 -77 72 -103 103 -117 140
          -11 27 -27 67 -38 89 -10 22 -21 52 -25 68 -3 15 -12 27 -19 27 -10 0 -15 -24
          -19 -87 -3 -49 -9 -158 -14 -243 -6 -85 -14 -168 -19 -185 -11 -35 6 -94 55
          -190 31 -62 205 -250 287 -310 22 -16 72 -59 111 -95 97 -90 152 -132 196
          -152 21 -9 68 -37 105 -60 115 -74 226 -135 268 -148 22 -7 69 -23 105 -36 36
          -13 71 -24 77 -24 6 0 24 -7 40 -15 15 -8 71 -31 123 -51 52 -20 114 -45 137
          -55 24 -10 45 -19 49 -19 3 0 31 -12 62 -26 89 -41 223 -73 383 -93 37 -5 73
          -12 80 -17 8 -5 43 -12 79 -16 126 -15 264 -46 385 -86 57 -19 113 -30 154
          -31 35 -1 68 -4 73 -7 4 -3 57 -9 116 -13 94 -7 278 -36 382 -61 19 -4 56 -13
          83 -19 26 -6 62 -11 80 -11 17 0 52 -6 77 -14 76 -24 162 -36 261 -36 52 0
          121 -7 152 -15 40 -10 107 -15 213 -15 147 0 194 -7 194 -30 0 -5 15 -33 33
          -62 45 -71 67 -134 67 -190 0 -40 4 -50 30 -68 28 -20 39 -21 172 -17 202 7
          295 22 373 60 276 136 288 142 320 181 38 47 68 105 120 231 20 50 55 111 77
          138 34 41 63 99 85 168 5 17 74 49 106 49 10 0 30 20 47 47 30 47 30 47 30
          199 0 205 6 243 38 251 47 11 101 49 131 91 35 48 51 57 107 65 44 5 108 31
          136 55 17 14 18 57 18 627 0 709 -14 628 125 720 48 32 106 82 137 118 91 106
          400 417 458 460 30 23 74 61 97 85 l43 44 0 182 0 183 -59 56 c-32 31 -71 59
          -87 62 -16 4 -46 13 -67 21 -24 9 -78 14 -156 14 -100 0 -120 3 -132 18 -18
          21 -49 88 -49 105 0 13 -140 157 -153 157 -5 0 -23 11 -41 25 -18 14 -36 25
          -40 25 -14 0 -116 81 -130 103 -7 12 -40 74 -73 137 -33 63 -77 144 -97 178
          -20 35 -36 66 -36 67 0 2 -13 23 -29 47 -15 24 -36 61 -46 83 -10 22 -24 47
          -31 55 -7 8 -37 63 -68 121 l-56 106 0 154 c0 117 -4 161 -15 183 -8 15 -15
          36 -15 45 0 26 -55 107 -86 128 -25 16 -62 18 -372 23 l-344 5 -38 44 c-37 42
          -39 43 -70 32 -18 -6 -52 -11 -76 -11 -40 0 -46 -4 -94 -59 l-51 -59 -107 -4
          c-106 -4 -107 -4 -152 26 -25 17 -64 48 -87 68 -24 21 -46 38 -51 38 -4 0 -17
          8 -28 18 -28 26 -143 112 -150 112 -2 0 -18 10 -35 22 -30 23 -69 95 -69 129
          0 10 -4 21 -10 24 -5 3 -10 33 -10 66 0 57 -2 62 -46 109 -31 34 -57 52 -80
          56 -19 3 -37 10 -40 15 -3 5 -35 9 -71 9 -36 0 -84 5 -107 11 -71 18 -150 41
          -174 50 -30 12 -869 12 -876 0z"
            />
            <path
              d="M9720 12930 c-139 -31 -256 -96 -308 -172 -39 -58 -87 -161 -106
          -228 -20 -68 -17 -364 4 -420 80 -218 106 -255 355 -500 248 -244 295 -326
          295 -510 l0 -92 -40 -40 c-38 -39 -42 -40 -90 -36 -54 5 -100 37 -100 69 0 9
          -7 30 -15 45 -11 21 -15 61 -15 141 0 61 -4 114 -9 117 -4 3 -97 6 -205 8
          -163 1 -199 -1 -207 -13 -12 -18 -5 -255 10 -334 41 -209 151 -359 304 -413
          136 -48 287 -59 405 -29 250 62 378 230 411 537 9 80 5 189 -9 225 -5 14 -10
          38 -10 53 -1 65 -74 228 -134 301 -20 24 -36 47 -36 51 0 5 -92 101 -205 214
          -214 214 -240 249 -271 364 -8 29 -14 65 -14 81 0 47 31 121 57 138 35 23 116
          22 142 -1 30 -28 54 -113 60 -216 l6 -95 199 -3 c110 -1 204 2 208 6 13 13 12
          212 -1 245 -6 16 -11 44 -11 63 0 42 -35 156 -63 204 -53 92 -213 220 -275
          220 -10 0 -23 5 -29 11 -13 13 -252 20 -303 9z"
            />
            <path
              d="M10648 12899 c-17 -9 -18 -76 -18 -1178 0 -1057 2 -1169 16 -1175 24
          -9 997 -7 1012 2 9 7 12 55 10 208 l-3 199 -278 5 c-232 4 -279 7 -287 20 -13
          20 -13 530 0 550 8 12 47 16 230 18 216 3 220 4 225 25 3 12 4 108 3 212 l-3
          190 -220 5 c-121 3 -226 9 -232 13 -10 6 -13 64 -13 231 0 196 2 225 17 237
          12 11 78 15 287 19 l271 5 3 204 c2 157 -1 206 -10 213 -20 12 -989 9 -1010
          -3z"
            />
            <path
              d="M11902 12902 c-10 -7 -12 -250 -10 -1183 l3 -1174 510 -3 c471 -2
          511 -1 523 15 13 19 18 369 4 390 -5 8 -86 12 -284 15 -210 2 -280 6 -290 16
          -21 20 -24 520 -4 547 13 19 28 20 234 23 l220 3 6 31 c10 49 7 344 -4 372
          l-10 26 -214 0 c-211 0 -215 0 -230 22 -22 32 -24 424 -2 454 14 18 31 19 287
          22 174 2 278 7 286 14 10 8 13 58 13 199 0 157 -3 190 -16 203 -14 14 -74 16
          -512 16 -273 0 -503 -4 -510 -8z"
            />
            <path
              d="M13163 12904 c-10 -5 -13 -250 -13 -1179 0 -1060 2 -1173 16 -1179
          23 -9 554 -6 654 4 103 10 138 19 213 55 112 55 211 191 246 338 14 59 16
          1514 2 1549 -5 13 -19 56 -31 95 -13 46 -34 88 -56 114 -19 22 -34 45 -34 49
          0 11 -71 62 -132 95 -105 56 -134 60 -508 62 -190 1 -351 -1 -357 -3z m654
          -459 l23 -26 0 -689 0 -688 -25 -30 c-29 -35 -100 -56 -156 -47 -30 5 -40 12
          -48 37 -14 40 -15 1405 -2 1442 12 29 39 36 126 31 50 -3 64 -8 82 -30z"
            />
            <path
              d="M13840 10348 c-19 -5 -62 -17 -94 -26 -90 -25 -197 -100 -246 -172
          -23 -33 -48 -79 -56 -103 -8 -23 -18 -51 -23 -62 -5 -11 -13 -36 -17 -55 -11
          -49 -11 -1536 0 -1585 5 -22 28 -80 51 -130 69 -144 184 -241 325 -272 30 -6
          126 -12 212 -12 l156 -1 36 -42 c20 -23 36 -45 36 -49 0 -13 102 -65 160 -83
          39 -12 82 -16 150 -14 l95 3 0 210 0 210 -60 5 c-33 3 -66 9 -72 13 -17 11
          -16 48 1 89 36 85 37 100 33 883 -2 491 -7 784 -13 810 -12 46 -49 122 -86
          180 -65 100 -166 160 -351 204 -56 13 -188 13 -237 -1z m163 -432 c18 -7 41
          -23 52 -36 20 -22 20 -36 20 -745 l0 -723 -24 -26 c-22 -23 -32 -26 -91 -26
          -59 0 -69 3 -91 26 l-24 26 0 718 c0 714 0 719 21 747 38 51 80 63 137 39z"
            />
            <path
              d="M8991 10298 c-4 -13 -14 -61 -20 -108 -7 -47 -17 -101 -21 -120 -5
          -19 -11 -57 -14 -85 -3 -27 -12 -90 -21 -140 -9 -49 -18 -110 -21 -135 -3 -25
          -9 -61 -14 -80 -5 -19 -14 -75 -20 -125 -20 -160 -22 -169 -28 -180 -4 -5 -10
          -39 -14 -75 -15 -149 -26 -218 -34 -231 -5 -8 -12 -43 -17 -79 -15 -124 -25
          -195 -41 -285 -17 -93 -32 -192 -45 -306 -4 -36 -11 -72 -16 -79 -13 -22 -45
          -301 -35 -313 3 -4 94 -6 201 -5 l194 3 13 28 c13 27 18 62 41 263 10 87 29
          137 60 154 11 5 69 10 129 10 94 0 111 -3 126 -19 19 -21 24 -46 65 -354 5
          -38 13 -73 18 -78 5 -5 97 -8 219 -7 l209 3 -1 60 c0 33 -6 94 -13 135 -7 41
          -16 107 -21 145 -4 39 -10 77 -14 85 -6 16 -23 117 -37 220 -12 95 -37 257
          -50 320 -12 64 -25 156 -36 255 -5 39 -12 76 -17 84 -5 8 -13 48 -18 90 -15
          142 -25 208 -32 220 -4 6 -12 46 -17 89 -5 42 -14 104 -19 137 -5 33 -13 92
          -16 130 -4 39 -11 79 -17 90 -10 20 -23 101 -33 215 -4 36 -11 71 -16 78 -8 9
          -76 12 -279 12 l-269 0 -9 -22z m299 -1057 c0 -26 5 -62 11 -81 10 -31 15 -69
          36 -251 8 -73 -6 -89 -77 -89 -92 0 -89 -3 -83 108 3 53 9 107 14 120 5 13 9
          46 9 73 0 27 7 66 15 86 8 19 15 46 15 59 0 12 8 30 17 39 16 16 17 16 30 -1
          7 -10 13 -38 13 -63z"
            />
            <path
              d="M10115 10308 c-3 -7 -4 -539 -3 -1183 l3 -1170 210 0 210 0 5 473 c5
          454 6 474 24 488 16 11 61 14 207 14 160 0 190 2 204 16 14 14 16 42 13 206
          -2 155 -6 190 -18 198 -8 5 -98 10 -200 10 -152 0 -190 3 -208 16 -22 15 -22
          18 -22 252 0 130 4 242 8 249 17 27 70 33 307 33 212 0 244 2 249 16 8 20 8
          358 0 378 -5 14 -58 16 -495 16 -383 0 -491 -3 -494 -12z"
            />
            <path
              d="M11305 10309 c-3 -8 -4 -540 -3 -1184 l3 -1170 200 -3 c110 -1 210 0
          223 3 l22 5 0 469 c0 535 -5 507 81 499 57 -6 103 -37 129 -88 19 -37 20 -60
          20 -387 0 -361 3 -392 46 -473 l13 -25 223 0 c249 0 234 -6 201 75 -16 39 -19
          89 -24 400 -4 221 -10 376 -18 410 -7 30 -17 78 -23 105 -13 61 -29 94 -80
          163 -43 58 -43 58 17 142 118 164 132 618 24 830 -26 53 -107 140 -159 172
          -14 8 -56 25 -95 38 -67 22 -87 23 -432 28 -302 4 -364 2 -368 -9z m599 -427
          c62 -27 76 -72 76 -247 0 -153 -12 -209 -52 -252 -18 -19 -32 -23 -84 -23 -97
          0 -94 -9 -94 269 0 174 3 241 13 253 24 31 69 31 141 0z"
            />
            <path
              d="M12693 10308 c-12 -19 -14 -2318 -2 -2340 9 -16 28 -18 212 -18 145
          0 206 3 215 12 17 17 17 2329 0 2346 -17 17 -414 17 -425 0z"
            />
            <path
              d="M14775 10308 c-10 -26 2 -1863 12 -1923 6 -33 18 -80 28 -105 9 -25
          19 -54 22 -65 3 -11 16 -34 29 -52 13 -17 24 -36 24 -40 0 -19 98 -103 155
          -133 86 -47 157 -60 306 -59 195 1 284 32 392 138 85 83 128 167 157 311 8 39
          10 334 8 995 l-3 940 -210 0 -210 0 -5 -925 c-5 -981 -4 -956 -52 -1007 -17
          -18 -32 -23 -75 -23 -65 0 -87 10 -110 53 -17 30 -18 99 -23 967 l-5 935 -218
          3 c-169 2 -219 0 -222 -10z"
            />
            <path
              d="M16175 10308 c-3 -7 -4 -539 -3 -1183 l3 -1170 520 0 520 0 3 209 c2
          161 -1 211 -10 218 -7 4 -135 8 -284 8 -240 0 -273 2 -285 17 -11 13 -14 71
          -14 280 0 250 1 265 19 279 16 11 64 14 226 14 147 0 209 3 218 12 17 17 17
          397 0 403 -7 3 -106 6 -220 8 -184 2 -209 4 -225 20 -16 16 -18 40 -18 232 0
          192 2 216 18 232 16 16 46 19 295 23 l277 5 0 200 0 200 -518 3 c-409 2 -519
          0 -522 -10z"
            />
            <path
              d="M1149 6633 c-2 -16 -3 -38 -1 -50 l3 -23 8460 0 8459 0 6 25 c3 14 3
          36 0 50 l-6 25 -8459 0 -8459 0 -3 -27z"
            />
            <path
              d="M1688 6220 c-50 -27 -83 -108 -65 -166 20 -68 52 -112 125 -170 42
          -34 62 -73 62 -123 0 -30 -6 -44 -22 -55 -44 -31 -78 -10 -78 47 0 27 -29 41
          -67 31 -29 -8 -34 -25 -21 -71 15 -50 24 -61 69 -84 74 -37 160 -13 196 54 23
          42 22 132 -1 175 -9 18 -40 56 -68 83 -112 110 -127 145 -84 196 20 23 27 26
          46 17 13 -5 28 -26 34 -44 10 -31 15 -35 46 -35 30 0 35 3 38 26 12 104 -109
          172 -210 119z"
            />
            <path
              d="M3945 6230 c-11 -4 -33 -22 -50 -40 l-30 -31 0 -232 c0 -216 1 -234
          20 -259 64 -85 196 -73 245 22 26 52 30 239 4 264 -19 19 -104 22 -122 4 -23
          -23 -14 -58 18 -69 23 -8 31 -19 36 -45 20 -105 -46 -195 -99 -136 -15 16 -17
          47 -17 219 0 181 2 201 18 216 41 37 85 9 98 -60 6 -30 10 -33 40 -33 28 0 35
          5 40 25 25 99 -102 197 -201 155z"
            />
            <path
              d="M4528 6223 c-27 -11 -42 -27 -57 -59 -19 -39 -21 -63 -21 -238 0
          -224 7 -251 70 -291 28 -17 54 -25 86 -25 41 0 51 5 85 39 38 38 39 41 39 109
          0 78 -14 98 -57 81 -20 -7 -23 -16 -23 -63 0 -60 -16 -86 -54 -86 -43 0 -46
          13 -46 231 0 175 2 210 16 223 8 9 23 16 32 16 33 0 46 -19 52 -72 5 -53 5
          -53 40 -53 l35 0 3 65 c3 63 2 66 -32 99 -28 27 -44 34 -83 37 -28 2 -63 -3
          -85 -13z"
            />
            <path
              d="M5058 6230 l-23 -6 0 -299 0 -300 127 -3 c117 -2 127 -1 132 16 13
          41 -2 52 -74 52 -103 0 -100 -3 -100 105 0 53 4 97 10 100 6 4 35 9 66 12 55
          6 55 6 52 37 -3 30 -5 31 -55 34 -28 2 -56 9 -63 17 -6 8 -10 42 -8 87 l3 73
          77 3 c82 3 105 16 93 51 -5 18 -16 20 -110 23 -58 2 -115 1 -127 -2z"
            />
            <path
              d="M5888 6227 c-79 -36 -90 -76 -86 -322 3 -216 9 -236 73 -272 73 -41
          151 -24 196 43 16 24 19 48 19 150 0 80 -4 124 -12 132 -7 7 -30 12 -53 12
          -23 0 -46 -5 -53 -12 -19 -19 -14 -56 11 -73 19 -14 22 -24 22 -91 0 -63 -3
          -78 -19 -90 -23 -16 -45 -18 -66 -4 -12 8 -16 44 -18 198 -4 216 2 256 39 260
          37 4 60 -19 66 -66 6 -42 6 -43 42 -40 35 3 36 5 39 46 7 102 -101 172 -200
          129z"
            />
            <path
              d="M8205 6230 l-30 -5 0 -300 0 -300 33 -3 c17 -2 33 -2 34 0 1 2 4 98
          7 213 4 130 10 210 16 210 6 0 11 -13 13 -30 2 -16 8 -34 13 -39 5 -6 9 -23 9
          -39 0 -16 6 -41 14 -55 8 -15 20 -54 27 -87 7 -33 16 -64 21 -69 4 -6 8 -22 8
          -36 0 -41 26 -70 64 -70 26 0 35 5 40 23 8 29 8 535 0 565 -5 17 -14 22 -39
          22 -42 0 -45 -11 -45 -167 0 -114 -10 -155 -25 -100 -3 12 -10 39 -15 60 -47
          182 -57 204 -88 209 -15 2 -40 1 -57 -2z"
            />
            <path
              d="M8675 6228 c-11 -6 -32 -22 -47 -36 l-28 -26 0 -237 c0 -224 1 -238
          21 -263 45 -58 123 -72 189 -35 57 32 70 70 70 204 0 103 -2 115 -19 125 -22
          11 -71 13 -95 4 -26 -10 -20 -61 9 -80 23 -15 25 -23 25 -83 0 -58 -3 -71 -23
          -89 -29 -27 -51 -28 -77 -2 -19 19 -20 33 -20 219 0 195 1 200 22 215 46 32
          87 11 97 -50 6 -37 8 -39 41 -39 33 0 35 2 38 34 5 52 -25 109 -69 132 -42 21
          -103 24 -134 7z"
            />
            <path
              d="M12155 6220 c-64 -42 -65 -43 -65 -290 0 -204 2 -228 19 -254 44 -67
          123 -84 195 -43 31 17 44 33 58 72 19 53 17 123 -5 137 -38 24 -77 -6 -77 -60
          0 -59 -10 -82 -38 -89 -20 -5 -31 0 -49 21 -22 26 -23 33 -23 210 0 172 1 185
          21 210 15 19 29 26 47 24 27 -3 35 -19 47 -90 6 -36 8 -38 44 -38 35 0 39 3
          45 30 6 30 -16 113 -37 138 -16 21 -76 42 -116 42 -20 0 -49 -9 -66 -20z"
            />
            <path
              d="M15750 6231 l-45 -6 0 -300 0 -300 42 -3 c51 -4 52 -2 56 148 l2 105
          28 3 c55 6 67 -18 67 -133 0 -115 7 -128 62 -123 l33 3 3 90 c3 89 -8 149 -35
          190 -17 25 -16 45 1 64 8 9 20 37 27 63 11 41 10 55 -4 102 -19 60 -41 79
          -102 90 -73 12 -90 13 -135 7z m134 -93 c22 -31 21 -126 -1 -150 -20 -22 -69
          -24 -77 -2 -11 28 -6 150 6 162 19 19 55 14 72 -10z"
            />
            <path
              d="M16378 6219 c-61 -32 -68 -60 -68 -294 0 -236 6 -263 70 -295 23 -12
          59 -20 88 -20 35 0 57 -6 76 -21 15 -12 37 -19 53 -17 23 2 29 9 31 34 2 24
          -2 33 -23 42 -21 10 -24 16 -19 39 4 16 11 32 15 37 5 6 9 96 9 200 -1 176 -3
          194 -23 236 -37 76 -129 102 -209 59z m112 -79 c19 -19 20 -31 18 -222 l-3
          -202 -27 -16 c-21 -11 -32 -12 -45 -4 -16 10 -18 34 -21 224 -2 148 1 217 9
          226 16 20 46 17 69 -6z"
            />
            <path
              d="M1982 6198 c3 -31 6 -33 48 -38 l45 -5 5 -257 c3 -170 9 -261 16
          -268 6 -6 27 -10 45 -8 l34 3 5 257 c3 151 9 261 15 267 6 5 24 11 40 13 26 3
          30 7 30 33 l0 30 -143 3 -143 3 3 -33z"
            />
            <path
              d="M2385 6219 c-3 -8 -4 -145 -3 -304 l3 -290 30 0 c37 0 43 18 44 147
          1 86 3 99 21 108 60 32 100 -28 100 -153 0 -45 4 -88 9 -95 5 -8 21 -12 37
          -10 l29 3 3 111 c2 91 -1 119 -16 155 -19 44 -19 46 -1 80 22 42 25 145 5 194
          -20 47 -47 58 -158 63 -78 4 -99 2 -103 -9z m158 -87 c32 -33 36 -86 11 -133
          -13 -26 -20 -30 -52 -27 l-37 3 -3 79 c-2 44 -1 85 2 93 9 22 51 14 79 -15z"
            />
            <path
              d="M2856 6218 c-2 -7 -8 -29 -15 -49 -6 -19 -11 -55 -11 -78 0 -23 -5
          -52 -12 -64 -7 -12 -16 -51 -20 -87 -5 -36 -13 -94 -19 -130 -5 -36 -13 -93
          -15 -128 l-6 -63 39 3 38 3 3 50 c3 58 19 75 72 75 50 0 68 -17 77 -75 l8 -50
          40 0 40 0 -4 65 c-1 36 -8 74 -14 85 -6 11 -15 48 -19 83 -4 34 -12 90 -18
          125 -5 34 -13 90 -18 125 -6 35 -15 76 -22 92 -12 28 -16 30 -67 30 -34 0 -55
          -4 -57 -12z m71 -180 c7 -11 19 -94 26 -182 3 -35 -20 -51 -58 -42 -27 7 -27
          8 -21 64 4 31 9 83 12 115 6 56 22 74 41 45z"
            />
            <path
              d="M3102 6198 c3 -31 6 -33 43 -36 22 -2 45 -8 52 -14 10 -8 13 -70 13
          -253 0 -134 3 -250 6 -259 8 -22 70 -22 78 0 3 9 6 125 6 259 0 181 3 246 12
          251 7 4 28 10 48 14 31 5 35 9 38 38 l3 32 -151 0 -151 0 3 -32z"
            />
            <path
              d="M3506 6208 c-3 -13 -6 -143 -6 -289 0 -198 3 -270 13 -282 11 -15 28
          -17 127 -15 l115 3 0 30 0 30 -75 3 c-56 2 -78 7 -87 19 -13 17 -18 148 -7
          177 4 10 25 17 65 22 45 5 60 11 65 26 10 31 -14 48 -67 48 -58 0 -69 14 -69
          92 0 77 10 87 100 90 l75 3 0 30 0 30 -121 3 c-119 3 -122 2 -128 -20z"
            />
            <path
              d="M4255 6218 c-3 -7 -4 -144 -3 -303 l3 -290 42 -3 c37 -3 43 0 48 20
          3 13 4 149 3 303 l-3 280 -43 3 c-28 2 -44 -1 -47 -10z"
            />
            <path
              d="M5405 6217 c-3 -7 -4 -143 -3 -302 l3 -290 30 0 30 0 6 120 c4 66 7
          149 8 183 1 45 5 62 13 60 11 -4 19 -26 34 -98 3 -14 12 -39 20 -57 8 -17 14
          -39 14 -48 0 -24 19 -92 37 -133 13 -31 17 -33 56 -30 l42 3 0 300 0 300 -32
          3 c-41 4 -42 -1 -46 -188 -1 -88 -6 -145 -12 -145 -5 0 -12 14 -13 30 -2 17
          -7 35 -11 40 -4 6 -13 37 -20 70 -7 33 -19 75 -27 93 -8 18 -14 43 -14 55 0
          13 -5 28 -12 35 -16 16 -97 16 -103 -1z"
            />
            <path
              d="M6212 5928 l3 -303 32 -3 c27 -3 33 1 38 20 3 13 4 149 3 303 l-3
          280 -38 3 -37 3 2 -303z"
            />
            <path
              d="M6415 6217 c-3 -7 -4 -143 -3 -302 l3 -290 35 0 35 0 6 200 c3 110 6
          201 7 203 9 11 21 -4 25 -30 6 -37 26 -112 37 -143 7 -19 13 -39 41 -147 22
          -87 25 -89 72 -86 l42 3 0 300 0 300 -36 3 -36 3 -5 -151 c-3 -94 -9 -155 -16
          -162 -9 -9 -12 -7 -12 8 0 10 -6 39 -14 64 -8 25 -19 63 -25 85 -5 22 -17 66
          -26 98 l-17 57 -54 0 c-35 0 -56 -5 -59 -13z"
            />
            <path
              d="M6845 6218 c-3 -7 -4 -144 -3 -303 l3 -290 125 0 125 0 0 30 0 30
          -77 3 c-49 2 -80 8 -88 16 -7 9 -10 47 -8 103 l3 88 63 9 c63 10 63 10 60 40
          -3 30 -5 31 -53 34 -27 2 -55 8 -62 14 -18 14 -18 142 0 156 7 6 46 12 87 14
          l75 3 0 30 0 30 -123 3 c-93 2 -124 0 -127 -10z"
            />
            <path
              d="M7207 6224 c-10 -10 -8 -576 1 -592 7 -9 39 -12 128 -10 l119 3 0 30
          0 30 -73 5 c-49 3 -76 10 -83 20 -13 20 -7 166 7 180 6 6 37 14 68 17 57 6 57
          6 54 37 -3 30 -5 31 -60 36 -31 3 -60 9 -65 13 -11 11 -10 143 0 154 5 4 41
          10 80 13 l72 5 0 30 0 30 -121 3 c-66 1 -123 -1 -127 -4z"
            />
            <path
              d="M7566 6214 c-9 -24 -7 -567 3 -582 5 -8 23 -12 47 -10 l39 3 2 105
          c4 143 6 150 46 150 51 0 57 -13 57 -119 0 -132 3 -141 51 -141 21 0 40 3 42
          8 2 4 0 61 -3 128 -5 90 -12 129 -25 152 -18 29 -18 31 0 58 14 21 18 47 18
          111 0 74 -3 86 -23 108 -31 33 -78 45 -171 45 -61 0 -78 -3 -83 -16z m164 -69
          c28 -15 30 -19 30 -76 0 -78 -11 -99 -54 -99 -22 0 -36 6 -40 16 -11 28 -6
          150 6 162 16 16 23 15 58 -3z"
            />
            <path
              d="M7970 5925 l0 -305 35 0 c24 0 37 6 45 19 11 21 14 529 4 569 -5 18
          -13 22 -45 22 l-39 0 0 -305z"
            />
            <path
              d="M9166 6214 c-9 -24 -7 -567 2 -582 7 -9 40 -12 133 -10 l124 3 0 30
          0 30 -70 3 c-51 2 -73 7 -82 19 -15 19 -18 160 -4 181 4 7 33 16 65 19 63 6
          71 13 62 49 -6 22 -11 24 -61 24 -65 0 -75 12 -75 92 0 76 11 87 95 90 l70 3
          0 30 0 30 -126 3 c-109 2 -128 0 -133 -14z"
            />
            <path
              d="M9528 6218 c-4 -11 15 -68 51 -153 23 -53 33 -106 29 -151 -4 -44
          -51 -185 -79 -241 -18 -34 -4 -55 32 -51 28 3 34 9 51 53 10 28 22 59 27 70 5
          11 12 28 15 38 11 35 39 13 61 -48 45 -121 40 -115 86 -115 41 0 41 0 34 32
          -4 18 -16 52 -26 76 -11 24 -19 49 -19 55 0 7 -6 24 -14 38 -7 15 -19 48 -26
          75 -14 54 -11 68 49 214 17 41 31 85 31 98 0 21 -4 23 -36 20 -36 -3 -37 -4
          -66 -83 -16 -44 -32 -87 -35 -95 -6 -20 -18 2 -50 88 -12 36 -28 71 -34 78
          -14 17 -76 18 -81 2z"
            />
            <path
              d="M9955 6217 c-3 -7 -4 -143 -3 -302 l3 -290 35 0 35 0 3 112 c2 73 7
          114 15 120 7 5 37 15 67 22 93 23 120 61 120 174 0 86 -22 130 -80 159 -43 22
          -188 26 -195 5z m155 -77 c27 -27 29 -144 3 -172 -20 -22 -69 -24 -77 -2 -11
          29 -7 169 6 182 18 18 45 15 68 -8z"
            />
            <path
              d="M10340 6173 c-1 -32 -3 -150 -6 -263 -3 -113 -1 -223 3 -245 l9 -40
          119 0 120 0 0 30 c0 30 -1 30 -60 33 -110 4 -105 -1 -105 107 0 51 4 97 10
          100 5 3 32 9 59 12 53 6 68 22 51 54 -8 14 -22 19 -53 19 -65 0 -68 6 -65 96
          l3 79 80 5 80 5 0 30 0 30 -122 3 -123 3 0 -58z"
            />
            <path
              d="M10697 6215 c-10 -25 -8 -568 2 -583 5 -8 21 -12 37 -10 l29 3 5 122
          c3 67 7 124 10 126 6 6 47 11 71 9 28 -3 43 -54 49 -157 l5 -100 35 0 35 0 3
          106 c2 87 -1 116 -17 157 -20 50 -20 50 0 88 16 31 20 54 17 112 -3 65 -6 75
          -35 104 -31 30 -35 31 -136 36 -91 4 -105 2 -110 -13z m161 -78 c38 -41 46
          -81 25 -124 -15 -32 -24 -39 -53 -41 -24 -3 -38 2 -47 14 -16 21 -17 127 -3
          155 14 26 52 24 78 -4z"
            />
            <path
              d="M11106 6214 c-3 -9 -6 -139 -6 -289 0 -150 3 -280 6 -289 4 -9 18
          -16 34 -16 16 0 30 7 34 16 3 9 6 139 6 289 0 150 -3 280 -6 289 -4 9 -18 16
          -34 16 -16 0 -30 -7 -34 -16z"
            />
            <path
              d="M11306 6199 c-8 -42 -6 -517 3 -549 7 -25 7 -25 126 -25 l120 0 0 30
          c0 30 -1 30 -60 33 -110 4 -105 -1 -105 107 0 52 4 97 10 100 6 3 38 9 72 13
          49 6 63 11 66 25 6 31 -17 47 -68 47 -79 0 -80 2 -80 91 0 90 -4 87 105 91 59
          3 60 3 60 33 l0 30 -121 3 -121 3 -7 -32z"
            />
            <path
              d="M11666 6215 c-9 -25 -7 -568 3 -583 5 -8 21 -12 37 -10 l29 3 6 175
          c4 96 7 187 8 203 1 31 21 37 21 6 0 -12 6 -35 14 -52 8 -18 17 -44 20 -59 3
          -16 9 -40 12 -55 3 -16 9 -37 14 -48 4 -11 13 -45 20 -75 23 -99 25 -102 72
          -98 l43 3 0 300 0 300 -35 0 -35 0 -2 -125 c-2 -69 -5 -135 -8 -147 -6 -26
          -25 -31 -25 -7 0 19 -28 112 -41 136 -5 10 -9 28 -9 40 0 13 -7 41 -14 63 -14
          39 -15 40 -69 43 -43 2 -56 -1 -61 -13z"
            />
            <path
              d="M12485 6218 c-3 -7 -4 -144 -3 -303 l3 -290 120 0 120 0 0 30 c0 30
          -1 30 -60 33 -111 4 -105 -2 -105 111 l0 99 65 7 c51 6 66 11 71 27 9 31 -14
          48 -65 48 -69 0 -72 5 -69 96 l3 79 80 5 80 5 0 30 0 30 -118 3 c-89 2 -119
          -1 -122 -10z"
            />
            <path
              d="M13025 6219 c-3 -8 -4 -145 -3 -304 l3 -290 75 -3 c177 -8 220 49
          220 289 0 61 -5 141 -11 179 -10 57 -17 74 -46 102 -32 31 -36 32 -133 36 -78
          3 -101 1 -105 -9z m169 -80 l26 -20 0 -193 c0 -191 0 -193 -24 -215 -39 -37
          -73 -23 -81 32 -9 58 -2 389 8 405 12 18 41 15 71 -9z"
            />
            <path
              d="M13425 6217 c-3 -7 -4 -143 -3 -302 l3 -290 40 0 40 0 2 105 c4 143
          6 150 47 150 56 0 69 -25 76 -148 l5 -107 33 -3 32 -3 0 131 c0 105 -3 138
          -17 160 -15 27 -15 30 0 54 13 19 17 48 17 114 0 80 -2 91 -23 110 -33 31 -78
          42 -170 42 -53 0 -79 -4 -82 -13z m156 -74 c70 -41 54 -173 -21 -173 -41 0
          -50 17 -50 97 0 58 3 74 18 82 22 13 19 14 53 -6z"
            />
            <path
              d="M13835 6217 c-3 -7 -4 -143 -3 -302 l3 -290 27 -3 c22 -3 30 2 38 23
          11 29 14 502 4 554 -6 26 -11 31 -36 31 -16 0 -31 -6 -33 -13z"
            />
            <path
              d="M14014 6188 c4 -24 11 -72 16 -108 6 -36 13 -96 16 -135 4 -38 11
          -76 18 -84 6 -8 14 -41 18 -75 4 -34 11 -83 15 -111 l8 -50 52 -3 c49 -3 53
          -1 67 27 9 17 16 53 16 81 0 28 5 61 12 73 7 12 16 54 20 92 5 39 13 102 19
          140 5 39 12 98 15 133 l6 62 -36 0 c-43 0 -41 4 -65 -215 -5 -38 -13 -86 -20
          -105 -6 -19 -11 -54 -11 -77 0 -24 -4 -43 -10 -43 -5 0 -10 18 -10 41 0 22 -5
          50 -12 62 -11 21 -22 99 -34 247 -3 36 -10 71 -16 78 -6 6 -28 12 -50 12 l-40
          0 6 -42z"
            />
            <path
              d="M14425 6218 c-3 -7 -4 -144 -3 -303 l3 -290 120 0 120 0 0 30 c0 30
          -1 30 -65 35 -36 3 -71 9 -77 13 -14 9 -18 163 -5 184 4 6 36 15 70 19 l63 7
          -3 31 c-3 30 -5 31 -61 36 -36 3 -62 11 -67 20 -15 23 -12 136 3 148 7 6 42
          12 77 14 65 3 65 3 65 33 l0 30 -118 3 c-89 2 -119 -1 -122 -10z"
            />
            <path
              d="M15038 6213 c-9 -10 -19 -47 -22 -83 -4 -36 -9 -87 -12 -115 -3 -27
          -9 -59 -14 -70 -5 -11 -11 -40 -14 -65 -14 -120 -26 -192 -37 -211 -16 -30 -4
          -49 30 -49 24 0 34 7 46 30 8 16 15 38 15 49 0 33 28 52 73 49 41 -3 42 -4 62
          -63 20 -55 24 -60 53 -63 27 -3 32 1 38 23 3 14 3 38 -1 53 -3 15 -10 54 -13
          87 -4 33 -11 66 -16 74 -5 7 -13 45 -18 85 -12 93 -25 174 -33 211 -3 17 -8
          40 -11 53 -4 19 -10 22 -58 22 -38 0 -57 -5 -68 -17z m72 -166 c0 -21 7 -60
          16 -88 30 -90 3 -164 -50 -143 -15 6 -17 17 -12 78 9 116 17 190 22 206 9 25
          24 -9 24 -53z"
            />
            <path
              d="M15365 6218 c-3 -7 -4 -144 -3 -303 l3 -290 34 -3 c20 -2 37 2 42 10
          5 7 9 63 9 125 0 62 4 115 10 118 5 3 32 9 60 12 43 5 51 10 56 30 9 34 -8 47
          -69 53 l-52 5 -3 78 c-2 48 2 84 8 93 8 8 38 14 83 16 l72 3 0 30 0 30 -123 3
          c-93 2 -124 0 -127 -10z"
            />
            <path
              d="M16106 6208 c-3 -13 -6 -140 -6 -283 0 -143 3 -270 6 -282 5 -19 13
          -23 49 -23 30 0 45 5 49 16 3 9 6 139 6 289 0 150 -3 280 -6 289 -4 11 -19 16
          -49 16 -36 0 -44 -4 -49 -22z"
            />
            <path
              d="M16716 6214 c-3 -9 -6 -122 -6 -252 0 -265 4 -286 69 -329 60 -40
          136 -27 191 32 25 26 25 27 28 253 2 124 1 245 -3 269 -6 41 -8 43 -40 43
          l-34 0 -3 -251 c-3 -250 -3 -251 -27 -270 -36 -29 -60 -24 -77 16 -11 26 -14
          85 -14 254 0 121 -3 226 -6 235 -4 10 -18 16 -39 16 -21 0 -35 -6 -39 -16z"
            />
            <path
              d="M17120 5925 l0 -306 128 3 127 3 0 30 c0 30 0 30 -65 33 -117 4 -111
          -2 -108 110 l3 97 62 10 c72 12 78 16 69 50 -6 23 -11 25 -59 25 -29 0 -58 5
          -65 12 -7 7 -12 42 -12 85 0 83 -3 81 110 85 65 3 65 3 65 33 l0 30 -127 3
          -128 3 0 -306z"
            />
          </g>
        </svg>
      </div>
    );
  }

  return (
    <div>
      {/* Render Navbar on non-admin routes */}
      {!isAdminRoute && (
        <nav className="fixed top-0 sm:static w-full z-50">
          <Navbar />
        </nav>
      )}

      <div
        className={`${!isAdminRoute ? "sm:pt-0 pt-16" : "pt-0"} ${
          isAdminDashboardRoute ? "pt-0" : ""
        }`}
      >
        {isAdminDashboardRoute && <AdminSidebar />}
        <main>
          <ScrollToTop />
          <Routes>
            {/* Home page now appears at the root "/" */}
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/joinus" element={<JoinUs />} />
            <Route path="/upcomingevents" element={<UpcomingEvents />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/admin" element={<Login />} />
            <Route path="/event-details" element={<EventDetailsPage />} />

            {/* Admin routes (untouched) */}
            <Route
              path="/admin/admindashboard"
              element={
                currentUser ? <AdminDashboard /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="/admin/admindashboard-content/manage-blogs"
              element={
                currentUser ? (
                  <div className="sm:ml-24">
                    <ManageBlogs />
                  </div>
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="/admin/admindashboard-content/manage-events"
              element={
                currentUser ? (
                  <div className="sm:ml-24">
                    <ManageEvents />
                  </div>
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="/admin/admindashboard-content/manage-gallery"
              element={
                currentUser ? (
                  <div className="sm:ml-24">
                    <ManageGallery />
                  </div>
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="/admin/admindashboard-content/manage-requests"
              element={
                currentUser ? (
                  <div className="sm:ml-24">
                    <ManageRequests />
                  </div>
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="/admin/admindashboard-content/manage-team"
              element={
                currentUser ? (
                  <div className="sm:ml-24">
                    <ManageTeam />
                  </div>
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
          </Routes>
        </main>
      </div>
      {/* Render Footer on non-admin routes */}
      {!isAdminRoute && (
        <footer className="mt-auto">
          <Footer />
        </footer>
      )}
    </div>
  );
}

export default App;
